export const validationRules = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  required: (value: any) => !!value,
  gt: (min: number) => (value: number) => value > min,
  gte: (min: number) => (value: number) => value >= min,
  lt: (max: number) => (value: number) => value < max,
  lte: (max: number) => (value: number) => value <= max,
  minLength: (minLengthInc: number) => (value: string) => value.length >= minLengthInc,
  maxLength: (maxLengthInc: number) => (value: string) => value.length <= maxLengthInc,
  isNumber: (value: string) => !isNaN(parseInt(value, 10)),
  pattern: (pattern: RegExp) => (value: string) => pattern.test(value),
  alphaNumericPattern: (value: string) => /^[a-zA-Z0-9 ]*$/.test(value),
  unicodePattern: (value: string) => /^[\p{L}']{1}[ \p{L}'-.]*[\p{L}.]{1}$/u.test(value?.trim()),
  unicodeNumericAndSpecialPattern: (value: string) =>
    /^[\p{L}'0-9]{1}[ \p{L}'-.0-9/]*$/u.test(value),
  ukPostcode: (value: string) => /^[a-z]{1}[a-z\d]{1,3}[ ]?\d{1}[a-z]{2}$/i.test(value),
  genericPostZipCode: (value: string) => /^[a-z\d-]{2,10}$/i.test(value),
  allowedValue: (allowedValues: string[]) => (value: string) => allowedValues.includes(value),
  phoneNumber: (v) => /^\d{1,4}\d{5,11}$/.test(v),
  ukPhoneNumber: (v) => /^44[1-9]{1}\d{8,9}$/.test(v),
};

export type ValidationRule = {
  rule: (v: string, values?: Record<string, any>) => boolean;
  errorMessage: string;
};

export const validate = (rules: Record<string, ValidationRule[]>) => (key: string, value: string, values?: Record<string, any>) =>
  rules[key].find(({ rule }) => !rule(value, values));

export const validateAll = (rules: Record<string, ValidationRule[]>) => (values) =>
  Object.keys(rules).reduce((res, cur) => {
    const result = validate(rules)(cur, values[cur], values);
    return result ? { ...res, [cur]: result } : res;
  }, {});
